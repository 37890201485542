import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
const { REACT_APP_API_URL } = process.env;

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (payload, { rejectWithValue }) => {
        try {
            const headers = {
                'Accept-Language': i18next.language,
            };
            const response = await axios.post(
                `${REACT_APP_API_URL}/api/login`,
                payload,
                { headers }
            );
            localStorage.setItem('userToken', response.data.token);
            localStorage.setItem(
                'userInfo',
                JSON.stringify(response.data.user)
            );
            return response;
        } catch (e) {
            return rejectWithValue(e.response?.data?.error || e.message);
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (payload, { rejectWithValue }) => {
        try {
            const headers = {
                'Accept-Language': i18next.language,
            };
            const response = await axios.post(
                `${REACT_APP_API_URL}/api/register`,
                payload,
                { headers }
            );
            return response;
        } catch (e) {
            return rejectWithValue(e.response?.data?.error || e.message);
        }
    }
);

export const updateUserDetails = createAsyncThunk(
    'auth/updateUserDetails',
    async (payload, { rejectWithValue, getState }) => {
        try {
            const headers = {
                'Accept-Language': i18next.language,
            };
            const token = getState().auth.token;
            if (token) {
                headers.authorization = `Bearer ${token}`;
            }
            const response = await axios.put(
                `${REACT_APP_API_URL}/api/account-management/update-account-details`,
                payload,
                { headers }
            );
            localStorage.setItem(
                'userInfo',
                JSON.stringify(response.data.user)
            );
            return response;
        } catch (e) {
            return rejectWithValue(e.response?.data?.error || e.message);
        }
    }
);

export const updateUserReadGuide = createAsyncThunk(
    'auth/updateUserReadGuide',
    async (payload, { rejectWithValue, getState }) => {
        try {
            const headers = {
                'Accept-Language': i18next.language,
            };
            const token = getState().auth.token;
            if (token) {
                headers.authorization = `Bearer ${token}`;
            }
            const response = await axios.put(
                `${REACT_APP_API_URL}/api/user-read-guide`,
                {},
                { headers }
            );
            localStorage.setItem(
                'userInfo',
                JSON.stringify(response.data.user)
            );
            return response;
        } catch (e) {
            return rejectWithValue(e.response?.data?.error || e.message);
        }
    }
);
