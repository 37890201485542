// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';
const { REACT_APP_API_URL } = process.env;

export const assessmentApi = createApi({
  reducerPath: 'assessmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Accept-Language', i18next.language);
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAssessmentsStats: builder.query({
      query: (params) => ({
        url: 'api/audits/stats',
        method: 'GET',
        params,
      }),
      providesTags: ['Assessments'],
    }),
    getUserAssessments: builder.query({
      query: (params) => ({
        url: 'api/audits/user-assessments',
        method: 'GET',
        params,
      }),
      providesTags: ['Assessments'],
    }),
    getOrganizationAssessments: builder.query({
      query: (params) => ({
        url: 'api/audits/organization-assessments',
        method: 'GET',
        params,
      }),
      providesTags: ['Assessments'],
    }),
    getAssessmentsByAirports: builder.query({
      query: (airportIds) => ({
        url: 'api/audits/assessments-by-airports',
        method: 'GET',
        params: {
          airports: airportIds,
        },
      }),
      providesTags: ['Assessments'],
    }),
    getAllAssessments: builder.query({
      query: (params) => ({
        url: 'api/audits/all-assessments',
        method: 'GET',
        params,
      }),
      providesTags: ['Assessments'],
    }),
    getCompletedAssessments: builder.query({
      query: () => ({
        url: 'api/audits/completed-assessments',
        method: 'GET',
      }),
      providesTags: ['Assessments'],
    }),
    getAssessmentDetails: builder.query({
      query: (assessmentId) => ({
        url: `api/audits/assessment/${assessmentId}`,
        method: 'GET',
      }),
      providesTags: ['Assessments'],
    }),
    getCompletedAssessmentPerDay: builder.query({
      query: () => ({
        url: 'api/completed-assessment-per-day',
        method: 'GET',
      }),
      providesTags: ['Assessments'],
    }),
    updateAssessmentDetails: builder.mutation({
      query: ({ data, assessmentId }) => ({
        url: `api/audits/assessment/${assessmentId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Assessments'],
    }),
    createAssessment: builder.mutation({
      query: (assessmentData) => ({
        url: `api/audits/user-assessments`,
        method: 'POST',
        body: assessmentData,
      }),
      invalidatesTags: ['Assessments'],
    }),
    deleteAssessment: builder.mutation({
      query: (assessmentId) => ({
        url: `api/audits/user-assessments/${assessmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Assessments'],
    }),
    getAssessmentLaunch: builder.query({
      query: (assessmentId) => ({
        url: `api/assessment/launch/${assessmentId}`,
        method: 'GET',
      }),
      providesTags: ['Assessments'],
    }),
    saveAssessmentAnswer: builder.mutation({
      query: ({ assessmentId, body }) => ({
        url: `api/assessment/launch/${assessmentId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Assessments'],
    }),
    submitAssessment: builder.mutation({
      query: ({
        assessmentId,
        recommendation,
        findings,
        submit,
        takeaways,
      }) => ({
        url: `api/assessment/submit/${assessmentId}`,
        method: 'PUT',
        body: {
          recommendation,
          findings,
          submit,
          takeaways,
        },
      }),
      invalidatesTags: ['Assessments'],
    }),
    getAssessmentsScorePerAirport: builder.query({
      query: (params) => ({
        url: `api/assessments/score-per-airport`,
        method: 'GET',
        params,
      }),
      providesTags: ['Assessments'],
    }),
  }),
});

export const {
  useGetAssessmentsStatsQuery,
  useLazyGetAssessmentsScorePerAirportQuery,
  useGetUserAssessmentsQuery,
  useGetOrganizationAssessmentsQuery,
  useLazyGetAssessmentsByAirportsQuery,
  useGetAllAssessmentsQuery,
  useDeleteAssessmentMutation,
  useCreateAssessmentMutation,
  useGetAssessmentDetailsQuery,
  useUpdateAssessmentDetailsMutation,
  useSubmitAssessmentMutation,
  useGetAssessmentLaunchQuery,
  useLazyGetAssessmentLaunchQuery,
  useSaveAssessmentAnswerMutation,
  useGetCompletedAssessmentsQuery,
  useGetCompletedAssessmentPerDayQuery,
} = assessmentApi;
