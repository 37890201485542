import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import styled from 'styled-components';

const LoginPage = lazy(() => import('./login/LoginPage'));
const RequestAccessPage = lazy(() =>
    import('./requestAccess/RequestAccessPage')
);
const UserManagement = lazy(() => import('./userManagement/UserManagement'));
const AirportManagement = lazy(() =>
    import('./airportManagement/AirportManagement')
);
const AirlineManagement = lazy(() =>
    import('./airlineManagement/AirlineManagement')
);
const QuestionGroupingManagement = lazy(() =>
    import('./questionGroupingManagement/QuestionGroupingManagement')
);
const AirportEdit = lazy(() => import('./airportEdit/AirportEdit'));
const AirlineEdit = lazy(() => import('./airlineEdit/AirlineEdit'));
const QuestionCategoryEdit = lazy(() =>
    import('./questionCategoryEdit/QuestionCategoryEdit')
);
const QuestionAdd = lazy(() => import('./questionAdd/QuestionAdd'));
const PageNotFound = lazy(() => import('./notFound/PageNotFound'));
const PrivacyPolicy = lazy(() => import('./privacy/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./terms/TermsOfUse'));
const ForgotPassword = lazy(() => import('./forgotPassword/ForgotPassword'));
const ProtectedRoute = lazy(() => import('../components/ProtectedRoute'));
const VerificationEmailPage = lazy(() =>
    import('./verificationEmailPage/VerificationEmailPage')
);
const RestorePassword = lazy(() => import('./restorePassword/RestorePassword'));
const SetPasswordPage = lazy(() => import('./setPasswordPage/SetPasswordPage'));
const AddUserPage = lazy(() => import('./addUserPage/AddUserComponent'));
const EditUserPage = lazy(() => import('./editUserPage/EditUserComponent'));
const AccountHome = lazy(() => import('./accountHome/AccountHome'));
const UserGuide = lazy(() => import('./userGuide/UserGuide'));
const AccountManagement = lazy(() =>
    import('./accountManagement/AccountManagement')
);
const CreateAssessments = lazy(() =>
    import('./createAssessments/CreateAssessments')
);
const EditAssessment = lazy(() => import('./editAssessment/EditAssessment'));
const AuditHome = lazy(() => import('./auditHome/AuditHome'));
const ReportHome = lazy(() => import('./reportHome/ReportHome'));
const AuditPerformance = lazy(() =>
    import('./auditPerformance/AuditPerformance')
);
const AuditorPerformance = lazy(() =>
    import('./auditorPerformance/AuditorPerformance')
);
const UserReport = lazy(() => import('./userReportPage/UserReport'));
const AirportPerformance = lazy(() =>
    import('./airportPerformance/AirportPerformance')
);
const LaunchAssessmentPage = lazy(() =>
    import('./launchAssessment/LaunchAssessmentPage')
);
const AssessmentPdfPreview = lazy(() =>
    import('./assessmentPdfPreview/AssessmentPdfPreview')
);
const CategoryEditPage = lazy(() => import('./categoryEditPage/CategoryEdit'));
const SubCategoryEditPage = lazy(() =>
    import('./subCategoryEditPage/SubCategoryEdit')
);
const QuestionEditPage = lazy(() => import('./questionEditPage/QuestionEdit'));
const AddAirport = lazy(() => import('./addAirportPage/AddAirport'));
const AddAirline = lazy(() => import('./addAirlinePage/AddAirline'));
const AirportDetails = lazy(() => import('./airportDetails/AirportDetails'));
const AirlineDetails = lazy(() => import('./airlineDetails/AirlineDetails'));
const ReferenceManagement = lazy(() =>
    import('./referenceManagement/ReferenceManagement')
);
const ReferenceAdd = lazy(() => import('./referenceAddPage/ReferenceAdd'));
const ReferenceEdit = lazy(() => import('./editReferencePage/ReferenceEdit'));

const SpinWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Router = () => {
    const { token, user } = useSelector((state) => state.auth);
    const authenticatedUser = token && user;
    const isAdminUser = authenticatedUser && user.role === 'admin';

    return (
        <Suspense
            fallback={
                <SpinWrapper>
                    <Spin size="large" />
                </SpinWrapper>
            }
        >
            <Routes>
                <Route
                    element={
                        <ProtectedRoute
                            isAllowed={!authenticatedUser}
                            redirectPath={'/audits/home'}
                        />
                    }
                >
                    <Route index element={<RequestAccessPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route
                        path="verification/:verificationToken"
                        element={<VerificationEmailPage />}
                    />
                    <Route
                        path="password-reset/:code"
                        element={<RestorePassword />}
                    />
                    <Route
                        path="set-password/:code"
                        element={<SetPasswordPage />}
                    />
                </Route>
                <Route
                    element={<ProtectedRoute isAllowed={authenticatedUser} />}
                >
                    <Route path="account-home" element={<AccountHome />} />
                    <Route path="guide" element={<UserGuide />} />
                    <Route
                        path="account-management"
                        element={<AccountManagement />}
                    />
                    <Route path="audits/home" element={<AuditHome />} />
                    <Route
                        path="audits/create-audit-template"
                        element={<CreateAssessments />}
                    />
                    <Route
                        path="audits/assessment/:assessmentId"
                        element={<EditAssessment />}
                    />
                    <Route
                        path="assessment/launch/:assessmentId"
                        element={<LaunchAssessmentPage />}
                    />
                    <Route
                        path="assessment/pdf-preview/:assessmentId"
                        element={<AssessmentPdfPreview />}
                    />
                </Route>
                <Route element={<ProtectedRoute isAllowed={isAdminUser} />}>
                    <Route path="reports/home" element={<ReportHome />} />
                    <Route
                        path="reports/audit-performance"
                        element={<AuditPerformance />}
                    />
                    <Route
                        path="reports/auditor-performance"
                        element={<AuditorPerformance />}
                    />
                    <Route
                        path="reports/user-report"
                        element={<UserReport />}
                    />
                    <Route
                        path="reports/airport-performance"
                        element={<AirportPerformance />}
                    />
                    <Route
                        path="user-management"
                        element={<UserManagement />}
                    />
                    <Route
                        path="questions-category-edit"
                        element={<QuestionCategoryEdit />}
                    />
                    <Route
                        path="question-edit/:questionId"
                        element={<QuestionEditPage />}
                    />
                    <Route
                        path="category-edit/:categoryId"
                        element={<CategoryEditPage />}
                    />
                    <Route
                        path="sub-category-edit/:subCategoryId"
                        element={<SubCategoryEditPage />}
                    />
                    <Route path="questions-add" element={<QuestionAdd />} />
                    <Route
                        path="user-management/add-user"
                        element={<AddUserPage />}
                    />
                    <Route
                        path="user-management/edit-user/:userId"
                        element={<EditUserPage />}
                    />
                    <Route
                        path="airport-management"
                        element={<AirportManagement />}
                    />
                    <Route
                        path="airlines-management"
                        element={<AirlineManagement />}
                    />
                    <Route
                        path="question-grouping-management"
                        element={<QuestionGroupingManagement />}
                    />
                    <Route
                        path="airport-management/:airportId"
                        element={<AirportEdit />}
                    />
                    <Route
                        path="airlines-management/:airlineId"
                        element={<AirlineEdit />}
                    />
                    <Route
                        path="airport-management/add-airport"
                        element={<AddAirport />}
                    />
                    <Route
                        path="airlines-management/add-airline"
                        element={<AddAirline />}
                    />
                    <Route
                        path="airline/:airlineId"
                        element={<AirlineDetails />}
                    />
                    <Route
                        path="airport/:airportId"
                        element={<AirportDetails />}
                    />
                    <Route
                        path="reference-management"
                        element={<ReferenceManagement />}
                    />
                    <Route
                        path="reference-management/add-reference"
                        element={<ReferenceAdd />}
                    />
                    <Route
                        path="reference-management/reference/:referenceId"
                        element={<ReferenceEdit />}
                    />
                </Route>
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="terms" element={<TermsOfUse />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Suspense>
    );
};

export default Router;
