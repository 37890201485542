import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Router from './pages/router';
import { createGlobalStyle } from 'styled-components';
import { store } from './store';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import ar_EG from 'antd/locale/ar_EG';
import en_US from 'antd/locale/en_US';
import uk_UA from 'antd/locale/uk_UA';

const GlobalStyle = createGlobalStyle`
html{
    min-height:100%;
    position:relative;
}
body{
    height:100%;
    min-height: 100vh;
}
:root {
   --primary-color: ${({ colorPrimary }) => colorPrimary};
}
`;
const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const urlLang = queryParameters.get('lang');
    if (urlLang === 'en' || urlLang === 'ar' || urlLang === 'ua') {
      i18n.changeLanguage(urlLang);
    }
  }, []);
  let locale;
  switch (i18n.resolvedLanguage) {
    case 'en':
      locale = en_US;
      break;
    case 'ar':
      locale = ar_EG;
      break;
    case 'ua':
      locale = uk_UA;
      break;
    default:
      locale = en_US;
      break;
  }
  const colorPrimary = '#022366';
  return (
    <Provider store={store}>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary,
            colorBgMask: '#022366a8',
          },
          components: {
            Switch: {
              colorPrimary: '#1AAE9F',
              colorPrimaryHover: '#1AAE9F',
            },
          },
        }}
      >
        <BrowserRouter>
          <GlobalStyle colorPrimary={colorPrimary} />
          <Router />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
