// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';
const { REACT_APP_API_URL } = process.env;

export const questionApi = createApi({
    reducerPath: 'questionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Accept-Language', i18next.language);
            const token = getState().auth.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getQuestionsWithCategories: builder.query({
            query: () => ({
                url: 'api/question-with-categories',
                method: 'GET',
            }),
            providesTags: ['questions', 'categories', 'subCategories'],
        }),
        getAllQuestionsWithCategories: builder.query({
            query: () => ({
                url: 'api/all-question-with-categories',
                method: 'GET',
            }),
            providesTags: ['questions', 'categories', 'subCategories'],
        }),
        getQuestionCategories: builder.query({
            query: () => ({
                url: 'api/question-categories',
                method: 'GET',
            }),
            providesTags: ['categories'],
        }),
        getQuestionCategoriesWithSubcategoriesPosition: builder.query({
            query: () => ({
                url: 'api/question-categories-with-subcategories-position',
                method: 'GET',
            }),
            providesTags: ['categories'],
        }),
        getQuestionSubCategories: builder.query({
            query: () => ({
                url: 'api/question-sub-categories',
                method: 'GET',
            }),
            providesTags: ['subCategories'],
        }),
        getQuestionSubCategory: builder.query({
            query: (subCategoryId) => ({
                url: `api/question-sub-category/${subCategoryId}`,
                method: 'GET',
            }),
            providesTags: ['subCategories'],
        }),
        editQuestionSubCategory: builder.mutation({
            query: ({ data, subCategoryId }) => ({
                url: `api/question-sub-category/${subCategoryId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['subCategories', 'categories'],
        }),
        addQuestionCategory: builder.mutation({
            query: (body) => ({
                url: `api/question-category`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['categories'],
        }),
        addQuestionSubCategory: builder.mutation({
            query: (body) => ({
                url: `api/question-sub-category`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['subCategories', 'categories'],
        }),
        editQuestionCategory: builder.mutation({
            query: ({ data, categoryId }) => ({
                url: `api/question-category/${categoryId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['categories'],
        }),
        deleteQuestionCategory: builder.mutation({
            query: (categoryId) => ({
                url: `api/question-category/${categoryId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['categories'],
        }),
        getQuestionCategory: builder.query({
            query: (categoryId) => ({
                url: `api/question-category/${categoryId}`,
                method: 'GET',
            }),
            providesTags: ['categories'],
        }),
        getQuestion: builder.query({
            query: (questionId) => ({
                url: `api/question/${questionId}`,
                method: 'GET',
            }),
            providesTags: ['questions'],
        }),
        addQuestion: builder.mutation({
            query: (body) => ({
                url: `api/question`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['questions', 'subCategories'],
        }),
        editQuestion: builder.mutation({
            query: ({ data, questionId }) => ({
                url: `api/question/${questionId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['questions', 'subCategories'],
        }),
        deleteQuestion: builder.mutation({
            query: (questionId) => ({
                url: `api/question/${questionId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['questions', 'subCategories'],
        }),
        bulkQuestionGrouping: builder.mutation({
            query: (data) => ({
                url: `api/question-grouping-management`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['questions'],
        }),
    }),
});

export const {
    useGetQuestionCategoriesQuery,
    useGetQuestionCategoriesWithSubcategoriesPositionQuery,
    useAddQuestionCategoryMutation,
    useAddQuestionSubCategoryMutation,
    useGetQuestionsWithCategoriesQuery,
    useAddQuestionMutation,
    useEditQuestionMutation,
    useEditQuestionCategoryMutation,
    useGetQuestionCategoryQuery,
    useGetQuestionQuery,
    useDeleteQuestionCategoryMutation,
    useDeleteQuestionMutation,
    useGetQuestionSubCategoriesQuery,
    useGetQuestionSubCategoryQuery,
    useEditQuestionSubCategoryMutation,
    useGetAllQuestionsWithCategoriesQuery,
    useBulkQuestionGroupingMutation
} = questionApi;
