import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authStore/authSlice';
import { userManagementApi } from './services/userManagementService';
import { authApi } from './services/authService';
import { assessmentApi } from './services/assessmentService';
import { questionApi } from './services/questionService';
import { locationManagementApi } from './services/locationManagementService';
import { referenceManagementApi } from './services/referenceService';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [userManagementApi.reducerPath]: userManagementApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [assessmentApi.reducerPath]: assessmentApi.reducer,
        [questionApi.reducerPath]: questionApi.reducer,
        [locationManagementApi.reducerPath]: locationManagementApi.reducer,
        [referenceManagementApi.reducerPath]: referenceManagementApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            userManagementApi.middleware,
            authApi.middleware,
            assessmentApi.middleware,
            questionApi.middleware,
            locationManagementApi.middleware,
            referenceManagementApi.middleware,
        ]),
    devTools: process.env.NODE_ENV !== 'production',
});
