import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';
const { REACT_APP_API_URL } = process.env;

export const userManagementApi = createApi({
    reducerPath: 'userManagementApi',
    tagTypes: ['Users'],
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Accept-Language', i18next.language);
            const token = getState().auth.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getRequestedUsers: builder.query({
            query: (params) => ({
                url: 'api/user-management/get-requested-users',
                method: 'GET',
                params,
            }),
            providesTags: ['requestedUsers'],
        }),
        getUsers: builder.query({
            query: (params) => ({
                url: 'api/user-management/get-users',
                method: 'GET',
                params,
            }),
            providesTags: ['Users'],
        }),
        getUserReports: builder.query({
            query: (params) => ({
                url: 'api/reports/users-report',
                method: 'GET',
                params,
            }),
            forceRefetch: () => true,
        }),
        getUserDetails: builder.query({
            query: (userId) => ({
                url: `api/user-management/get-users/${userId}`,
                method: 'GET',
            }),
            providesTags: ['Users'],
        }),
        updateUserDetails: builder.mutation({
            query: ({ formData, userId }) => ({
                url: `api/user-management/update-user/${userId}`,
                method: 'PUT',
                body: formData,
            }),
            invalidatesTags: ['Users'],
        }),
        passwordReset: builder.mutation({
            query: (userId) => ({
                url: `api/user-management/reset-user-password/${userId}`,
                method: 'GET',
            }),
        }),
        updateUserStatus: builder.mutation({
            query: ({ userId, status }) => ({
                url: `api/user-management/update-user-status/${userId}`,
                method: 'PUT',
                body: { status },
            }),
            invalidatesTags: ['Users'],
        }),
        addUser: builder.mutation({
            query: (userData) => ({
                url: `api/user-management/create-user`,
                method: 'POST',
                body: userData,
            }),
            invalidatesTags: ['Users'],
        }),
        deleteRequestedUser: builder.mutation({
            query: (userId) => ({
                url: `api/user-management/delete-requested-user/${userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['requestedUsers'],
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `api/user-management/delete-user/${userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Users'],
        }),
        updateRequestedUser: builder.mutation({
            query: ({ formValues, userId }) => ({
                url: `api/user-management/update-requested-user/${userId}`,
                method: 'PUT',
                body: formValues,
            }),
            invalidatesTags: ['requestedUsers', 'Users'],
        }),
        getAuditors: builder.query({
            query: () => ({
                url: 'api/get-auditors',
                method: 'GET',
            }),
            providesTags: ['Users'],
        }),
        getAuditorsPerformance: builder.query({
            query: (params) => ({
                url: 'api/auditors-performance',
                method: 'GET',
                params,
            }),
            providesTags: ['Users'],
        }),
    }),
});

export const {
    useGetRequestedUsersQuery,
    useGetUsersQuery,
    useGetUserDetailsQuery,
    useUpdateUserStatusMutation,
    useAddUserMutation,
    useDeleteRequestedUserMutation,
    useDeleteUserMutation,
    useUpdateRequestedUserMutation,
    useUpdateUserDetailsMutation,
    usePasswordResetMutation,
    useGetAuditorsQuery,
    useGetAuditorsPerformanceQuery,
    useGetUserReportsQuery,
} = userManagementApi;
