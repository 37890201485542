import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './assets/locales/en/translation.json';
import translationAR from './assets/locales/ar/translation.json';
import translationUA from './assets/locales/ua/translation.json';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'ar'];

const resources = {
    en: {
        translation: translationEN,
    },
    ar: {
        translation: translationAR,
    },
    ua: {
        translation: translationUA,
    },
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,

        detection: {
            checkWhitelist: true,
        },

        debug: process.env.NODE_ENV !== 'production',

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
