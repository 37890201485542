import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';
const { REACT_APP_API_URL } = process.env;

export const referenceManagementApi = createApi({
    reducerPath: 'referenceManagementApi',
    tagTypes: ['References'],
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Accept-Language', i18next.language);
            const token = getState().auth.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getQuestionReference: builder.query({
            query: (params) => ({
                url: '/api/question-references',
                method: 'GET',
                params,
            }),
            providesTags: ['References'],
        }),
        getAllReference: builder.query({
            query: (params) => ({
                url: '/api/all-references',
                method: 'GET',
                params,
            }),
            providesTags: ['References'],
        }),
        deleteReference: builder.mutation({
            query: (referenceId) => ({
                url: `api/references/${referenceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['References'],
        }),
        addReference: builder.mutation({
            query: (body) => ({
                url: `api/reference`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['References'],
        }),
        getReferenceDetails: builder.query({
            query: (referenceId) => ({
                url: `/api/reference-details/${referenceId}`,
                method: 'GET',
            }),
            providesTags: ['References'],
        }),
        updateReferenceDetails: builder.mutation({
            query: ({ data, referenceId }) => ({
                url: `api/reference/${referenceId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['References'],
        }),
    }),
});

export const {
    useGetQuestionReferenceQuery,
    useDeleteReferenceMutation,
    useAddReferenceMutation,
    useGetReferenceDetailsQuery,
    useUpdateReferenceDetailsMutation,
    useGetAllReferenceQuery
} = referenceManagementApi;
