import { createSlice } from '@reduxjs/toolkit';
import { loginUser, registerUser, updateUserDetails, updateUserReadGuide } from './authActions';

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null;

const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

const initialState = {
    user: userInfo,
    error: null,
    token: userToken,
    isLoading: false,
    success: false,
    updated: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('userToken'); // deletes token from storage
            localStorage.removeItem('userInfo'); // deletes token from storage
            state.user = null;
            state.token = null;
            state.isLoading = false;
        },
        autoLogin: (state, action) => {
            localStorage.setItem('userToken', action.payload.token);
            localStorage.setItem(
                'userInfo',
                JSON.stringify(action.payload.user)
            );
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isLoading = false;
        },
        resetErrorsAndSuccess: (state) => {
            state.error = null;
            state.success = false;
            state.isLoading = false;
            state.updated = false;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.data.user;
                state.token = action.payload.data.token;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(registerUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(registerUser.fulfilled, (state) => {
                state.isLoading = false;
                state.success = true;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.isLoading = false;
                state.success = false;
                state.error = action.payload;
            })
            .addCase(updateUserDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateUserDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.updated = true;
                state.user = action.payload.data.user;
            })
            .addCase(updateUserDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.updated = false;
                state.error = action.payload;
            })
            .addCase(updateUserReadGuide.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateUserReadGuide.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.data.user;
            })
            .addCase(updateUserReadGuide.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});
// export actions
export const { logout, resetErrorsAndSuccess, autoLogin } = authSlice.actions;
export default authSlice.reducer;
