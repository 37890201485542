import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';
const { REACT_APP_API_URL } = process.env;

export const locationManagementApi = createApi({
    reducerPath: 'locationManagementApi',
    tagTypes: ['airport', 'airlines'],
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Accept-Language', i18next.language);
            const token = getState().auth.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAirports: builder.query({
            query: (params) => ({
                url: 'api/airport-management',
                method: 'GET',
                params,
            }),
            providesTags: ['airport'],
        }),
        getAirlines: builder.query({
            query: (params) => ({
                url: 'api/airlines-management',
                method: 'GET',
                params,
            }),
            providesTags: ['airlines'],
        }),
        getAirlineDetails: builder.query({
            query: (airlineId) => ({
                url: `api/airlines-management/${airlineId}`,
                method: 'GET',
            }),
            providesTags: ['airlines'],
        }),
        getAirportDetails: builder.query({
            query: (airportId) => ({
                url: `api/airport-management/${airportId}`,
                method: 'GET',
            }),
            providesTags: ['airport'],
        }),
        updateAirportDetails: builder.mutation({
            query: ({ formData, airportId }) => ({
                url: `api/location-management/location/${airportId}`,
                method: 'PUT',
                body: formData,
            }),
            invalidatesTags: ['airport'],
        }),
        updateAirlineDetails: builder.mutation({
            query: ({ formData, airlineId }) => ({
                url: `api/location-management/location/${airlineId}`,
                method: 'PUT',
                body: formData,
            }),
            invalidatesTags: ['airlines'],
        }),
        getAvailableAirports: builder.query({
            query: () => ({
                url: 'api/available-airports',
                method: 'GET',
            }),
            providesTags: ['airport', 'airlines'],
        }),
        updateAirlineStatus: builder.mutation({
            query: ({ airlineId, status }) => ({
                url: `api/location-management/location-status/${airlineId}`,
                method: 'PUT',
                body: { status },
            }),
            invalidatesTags: ['airlines'],
        }),
        updateAirportStatus: builder.mutation({
            query: ({ airportId, status }) => ({
                url: `api/location-management/location-status/${airportId}`,
                method: 'PUT',
                body: { status },
            }),
            invalidatesTags: ['airport'],
        }),
        addAirport: builder.mutation({
            query: (airportData) => ({
                url: `api/airport-management/airport`,
                method: 'POST',
                body: airportData,
            }),
            invalidatesTags: ['airport'],
        }),
        addAirline: builder.mutation({
            query: (airlineData) => ({
                url: `api/airlines-management/airline`,
                method: 'POST',
                body: airlineData,
            }),
            invalidatesTags: ['airlines'],
        }),
        getAvailableAllAirports: builder.query({
            query: () => ({
                url: `/api/available-all-airports`,
                method: 'GET',
            }),
            providesTags: ['airport'],
        }),
    }),
});

export const {
    useGetAirlinesQuery,
    useGetAirportsQuery,
    useGetAirlineDetailsQuery,
    useGetAirportDetailsQuery,
    useUpdateAirlineDetailsMutation,
    useUpdateAirlineStatusMutation,
    useAddAirlineMutation,
    useAddAirportMutation,
    useGetAvailableAirportsQuery,
    useUpdateAirportDetailsMutation,
    useUpdateAirportStatusMutation,
    useGetAvailableAllAirportsQuery
} = locationManagementApi;
